<template>
	<div class="layout">
		<div class="home">
			<Header />
			<div class="banner padtop">
				<div class="wrap">
					<div class="txt">
						<img src="@/assets/images/logo.png" alt="" />
						<h4>通过最新的人工智能系统，大数据以及<br>最顶尖的教育团队为您提供专业的及<br>学校及相关专业的建议，和职业<br>规划及让您看未来不迷茫。</h4>
						<router-link to="/page2" active-class="active-link">
						<div class="btn">
							开始测试 <img src='@/assets/images/ico1.png' alt='' />
						</div>
						</router-link>
					</div>
					<div class="num">
						<ul>
							<li>
								<h3>20+</h3>
								<h5>学校</h5>
							</li>
							<li>
								<h3>100+</h3>
								<h5>专业</h5>
							</li>
							<li>
								<h3>100+</h3>
								<h5>职业</h5>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="hezuo">
				<div class="wrap">
					<div class="lt">
						<div class="tit">
							合作伙伴
						</div>
						<ul>
							<li><img src="@/assets/images/qms14.png" alt=""></li>
							<li><img src="@/assets/images/qms15.png" alt=""></li>
							<li><img src="@/assets/images/qms16.png" alt=""></li>
							<li><img src="@/assets/images/qms17.png" alt=""></li>
							<li><img src="@/assets/images/qms18.png" alt=""></li>
							<li><img src="@/assets/images/qms19.png" alt=""></li>
						</ul>
					</div>
					<div class="pic">
						<img src="@/assets/images/pic1.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	// @ is an alias to /src
	import Header from '@/components/Header.vue'

	export default {
		name: 'HomeView',
		components: {
			Header
		}
	}
</script>